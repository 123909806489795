<template>
  <div>
    <!--begin::Payment-->
    <!--begin::InvoiceAlert-->
    <div class="row mb-6 mt-6 mt-lg-0"
         v-if="false">
      <div class="col-md-12">
        <InvoiceAlert title="Ödenmemiş Fatura Bildirimi"
                      :per="1"
                      price="2000,90">
        </InvoiceAlert>
      </div>
    </div>
    <!--end::InvoiceAlert-->

    <!--begin::Resources-->
    <!-- <b-card no-body
            class="mb-5">
      <div class="row my-6">
        <div class="col-md-6 mtp-25">
          <ResourceChart></ResourceChart>
        </div>
        <div class="col-md-6 mtp-25 left-border">
          <ServiceList></ServiceList>
        </div>
      </div>
    </b-card> -->
    <!--end::Resources-->

    <!--begin::CreditCard and CurrentSubs-->
    <div class="row mb-6"
         v-if="hasCard">
      <div class="col-12 mb-6"
           v-if="false">
        <CurrentSubs></CurrentSubs>
      </div>
      <div class="col-12 mb-6 mb-lg-0">
        <CreditCardList :isCreditCardLoading="isCreditCardLoading"
                        @addedCreditCard="addedCreditCard"
                        @verifyCreditCard="addedCreditCard"
                        :addNewCardOperation="addNewCardOperation"></CreditCardList>
      </div>
    </div>
    <div class="row mb-6"
         v-else>
      <div class="col-md-12 col-lg-12 mb-6 mb-lg-0">
        <CreditCard @childToParent="addPayment"
                    :isCreditCardLoading="isCreditCardLoading"></CreditCard>
      </div>
      <div class="col-md-12 col-lg-6"
           v-if="false">
        <CurrentSubs class="column"></CurrentSubs>
      </div>
    </div>
    <!--end::CreditCard and CurrentSubs-->

    <!--begin::BillingHistory-->
    <div class="row">
      <div class="col-md-12">
        <BillingHistory></BillingHistory>
      </div>
    </div>
    <!--end::BillingHistory-->

    <!--
        Modal Test Buttons 
    -->
    <div v-if="false">
      <div class="mt-5">
        <b-button v-b-modal.popup>Popup Test</b-button>
        <PopupModal></PopupModal>
      </div>
      <div class="mt-5">
        <b-button v-b-modal.modal-invoice>Invoice Test</b-button>
        <InvoiceModal></InvoiceModal>
      </div>
    </div>
    <!--end::Payment-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { GET_CREDIT_CARDS } from '@/core/services/store/creditCards.module'
import ApiService from '@/core/services/api.service'
import InvoiceAlert from '@/components/InvoiceAlert'
import CreditCard from '@/components/CreditCard'
import CreditCardList from '@/components/CreditCardList'
import CurrentSubs from '@/components/CurrentSubs'
import BillingHistory from '@/components/BillingHistory'
import PopupModal from '@/components/PopupModal'
import InvoiceModal from '@/components/InvoiceModal'
import ResourceChart from '@/components/payment/ResourceChart'
import ServiceList from '@/components/payment/ServiceList'

export default {
  name: 'payment',
  data() {
    return {
      hasCard: false,
      isCreditCardLoading: false,
      addNewCardOperation: false,
    }
  },
  mounted() {
    this.setBreadcrumb()

    this.$ml.bus.$on('vueml-language-changed', (payload) => {
      this.setBreadcrumb()
    })

    this.getCreditCards()
  },
  methods: {
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$ml.get('payment'), route: 'payment' },
      ])
    },
    addPayment() {
      this.hasCard = !this.hasCard
      this.addNewCardOperation = true
    },
    getCreditCards() {
      this.isCreditCardLoading = true
      this.$store
        .dispatch(GET_CREDIT_CARDS)
        .then((res) => {
          if (res.data.length > 0) this.hasCard = true
          this.isCreditCardLoading = false
        })
        .catch((err) => {
          this.isCreditCardLoading = false
        })
    },
    addedCreditCard() {
      this.getCreditCards()
    },
  },
  components: {
    InvoiceAlert,
    CreditCard,
    CreditCardList,
    CurrentSubs,
    BillingHistory,
    PopupModal,
    InvoiceModal,
    ResourceChart,
    ServiceList,
  },
}
</script>
