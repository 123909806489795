<template>
  <b-modal id="popup" :body-bg-variant="variant" centered hide-header hide-footer size="sm">
    <div class="mb-5" :class="variant ? 'text-white' : ''">
      <h3 class="font-size-secondary">{{ title }}</h3>
    </div>

    <p :class="variant ? 'text-white' : ''">{{ text }}</p>

    <div class="mt-5">
      <b-button
        block
        :variant="variant ? 'outline-' + variant : 'primary'"
        :class="variant ? 'bg-white' : ''"
        @click="$bvModal.hide('popup')"
      >Okay</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    /**
     * Popup bootstrap color variant
     * `danger, success, warning`
     */
    variant: {
      type: String,
      default: '',
    },
    /**
     * Popup title string
     */
    title: {
      type: String,
      default: 'Title',
    },
    /**
     * Popup content text
     */
    text: {
      type: String,
      default: 'Text',
    },
  },
}
</script>
