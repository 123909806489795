<template>
  <Card class="card-dashed">
    <CardLoader v-if="isCreditCardLoading"
                class="col-md-6">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>

    <template #header>
      <div class="title pt-5">
        <h3 class="text-dark font-size-secondary m-0">{{ $ml.get('creditCard') }}</h3>
      </div>
    </template>

    <template v-if="!isCreditCardLoading">
      <p class="text-dark font-weight-light">
        {{ $ml.get('noCreditCardInfo') }}
      </p>

      <div class="button mt-5">
        <ButtonPrimary class="px-12 w-auto"
                       @click.native="addPayment">
          {{ $ml.get('addPaymentMethod') }}
        </ButtonPrimary>
      </div>

      <div class="creditcard-logos d-flex mt-5">
        <div class="mr-4">
          <img src="/image/visa.png"
               class="img-fluid"
               alt="visa" />
        </div>
        <div class="mr-4">
          <img src="/image/mastercard.png"
               class="img-fluid"
               alt="mastercard" />
        </div>
        <div class="mr-4">
          <img src="/image/amex.png"
               class="img-fluid"
               alt="american express" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import ButtonPrimary from '@/components/buttons/ButtonPrimary'
import CardLoader from '@/view/content/CardLoader'

export default {
  name: 'CreditCard',
  props: {
    isCreditCardLoading: Boolean,
  },
  methods: {
    addPayment() {
      this.$emit('childToParent', this.addPayment)
    },
  },
  components: {
    Card,
    ButtonPrimary,
    CardLoader,
  },
}
</script>
