<template>
  <Card class="billing-history-list">
    <template #header>
      <div class="title pt-5">
        <h3 class="text-dark font-size-secondary m-0">{{ $ml.get('billingHistory') }}</h3>
      </div>
    </template>

    <v-data-table :headers="headers"
                  :items="items"
                  :options.sync="options"
                  :items-per-page="16"
                  :server-items-length="totalItems"
                  :loading="loading"
                  class="elevation-1"
                  @update:page="changePagination">

      <!-- HEADER TITLES -->
      <template v-slot:header.custom_invoice_number>
        {{$ml.get('invoiceNo')}}
      </template>
      <template v-slot:header.detailed_report>
        {{$ml.get('isOldInvoice')}}
      </template>
      <template v-slot:header.invoice_date>
        {{$ml.get('date')}}
      </template>
      <template v-slot:header.note>
        {{$ml.get('description')}}
      </template>
      <template v-slot:header.amount>
        {{$ml.get('unitPrice')}}
      </template>
      <template v-slot:header.total>
        {{$ml.get('total')}}
      </template>
      <template v-slot:header.is_paid>
        {{$ml.get('paymentStatus')}}
      </template>

      <!-- CUSTOM VALUE  -->
      <template v-slot:item.custom_invoice_number="{ item }">
        {{ item.custom_invoice_number ? item.custom_invoice_number : 'N/A' }}
      </template>

      <template v-slot:item.detailed_report="{ item }">
        <b-badge v-if="item.detailed_report && item.detailed_report.id"
                 v-b-tooltip.hover
                 :title="item.detailed_report.id"
                 class="rounded-sm cursor-pointer"
                 variant="primary">
          {{ $ml.get('yes') }}
        </b-badge>
        <b-badge v-else
                 class="rounded-sm"
                 variant="primary">
          {{ $ml.get('no') }}
        </b-badge>
      </template>

      <template v-slot:item.invoice_date="{ item }">
        {{$customFunctions.setMomentWithFormat(item.invoice_date,'ll')}}
      </template>

      <template v-slot:item.note="{ item }">
        {{ item.note ? item.note : 'N/A' }}
      </template>

      <template v-slot:item.amount="{ item }">
        {{ `${item.amount} ${item.currency_code}` }}
      </template>

      <template v-slot:item.total="{ item }">
        <span class="font-weight-bolder"
              v-if="item.total">{{ `${item.total} ${item.currency_code}` }}</span>
        <span class="font-weight-bolder"
              v-else>{{ `${item.amount} ${item.currency_code} + ${$ml.get('vat')}` }}</span>
      </template>

      <template v-slot:item.is_paid="{ item }">
        <!-- <ButtonDownload class="mr-2">Download</ButtonDownload>
        <ButtonPrint>Print</ButtonPrint> -->
        <a href="#"
           v-if="!item.is_paid"
           @click="payInvoice(item)"
           class="btn btn-primary btn-sm rounded-sm">{{ $ml.get('payNow') }}</a>
        <span v-else>{{ $ml.get('paid') }}</span>
      </template>

      <template v-slot:no-data>
        {{ $ml.get('noDataAvailable') }}
      </template>
    </v-data-table>

    <PayInvoiceModal :selectedInvoice="selectedInvoice"
                     @invoiceBeenPaid="invoiceBeenPaid"></PayInvoiceModal>
  </Card>
</template>

<script>
import ApiService from '@/core/services/api.service'
import Card from '@/components/Card'
import ButtonDownload from '@/components/buttons/ButtonDownload'
import ButtonPrint from '@/components/buttons/ButtonPrint'
import PayInvoiceModal from '@/components/PayInvoiceModal'

export default {
  name: 'BillingHistory',
  data() {
    return {
      dialog: false,
      perPage: 0,
      totalItems: 0,
      items: [],
      loading: false,
      options: {},
      headers: [
        {
          text: this.$ml.get('invoiceNo'),
          value: 'custom_invoice_number',
          sortable: false,
        },
        {
          text: this.$ml.get('isOldInvoice'),
          value: 'detailed_report',
          sortable: false,
        },
        { text: this.$ml.get('date'), value: 'invoice_date', sortable: false },
        { text: this.$ml.get('description'), value: 'note', sortable: false },
        { text: this.$ml.get('unitPrice'), value: 'amount', sortable: false },
        { text: this.$ml.get('total'), value: 'total', sortable: false },
        {
          text: this.$ml.get('paymentStatus'),
          value: 'is_paid',
          sortable: false,
        },
      ],
      selectedInvoice: {},
    }
  },
  created() {
    this.getInvoiceList()
      .then((res) => {
        this.setInvoiceData(res)
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    getInvoiceList() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        ApiService.get(
          `invoicing?position=created_at|DESC&page=${page ? page : 1}`
        )
          .then(({ data }) => {
            this.loading = false
            resolve(data)
          })
          .catch((err) => {
            this.loading = false
            reject(err)
          })
      })
    },
    setInvoiceData(res) {
      this.items = res.data
      this.totalItems = res.meta.pagination.total
      this.perPage = res.meta.pagination.count
    },
    changePagination() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
    payInvoice(item) {
      this.selectedInvoice = item
      this.$bvModal.show('pay-invoice-modal')
    },
    invoiceBeenPaid() {
      this.getInvoiceList().then((res) => {
        this.setInvoiceData(res)
      })
    },
  },
  watch: {
    options: {
      handler() {
        this.getInvoiceList().then((res) => {
          this.setInvoiceData(res)
        })
      },
      deep: true,
    },
  },
  components: {
    Card,
    ButtonDownload,
    ButtonPrint,
    PayInvoiceModal,
  },
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  .billing-history-list {
    .v-data-table__mobile-row__header {
      width: calc(100vw - 104px) !important;
    }
  }
}
</style>