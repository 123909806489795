<template>
  <div class="service-list px-5">
    <div class="title">{{$ml.get('serviceListUsed')}}</div>

    <div class="d-flex list-header">
      <div>
        NAME
      </div>
      <div>
        LOREM
      </div>
      <div>
        IPSUM
      </div>
    </div>

    <div v-for="item in items"
         :key="item.id"
         class="d-flex list-detail">
      <div>
        {{item.name}}
      </div>
      <div>
        {{item.lorem}}
      </div>
      <div>
        {{item.ipsum}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceList',
  data() {
    return {
      items: [
        {
          id: 1,
          name: '-',
          lorem: '-',
          ipsum: '-',
        },
        {
          id: 2,
          name: '-',
          lorem: '-',
          ipsum: '-',
        },
        {
          id: 3,
          name: '-',
          lorem: '-',
          ipsum: '-',
        },
        {
          id: 4,
          name: '-',
          lorem: '-',
          ipsum: '-',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.service-list {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #212121;
  }
  .list-header {
    margin-top: 35px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #b5b5c3;
    border-bottom: 1px solid #e5eaee;
    padding-bottom: 20px;
    div {
      width: 33%;
    }
  }
  .list-detail {
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #464e5f;
    border-bottom: 1px solid #e5eaee;
    padding-bottom: 20px;
    div {
      width: 33%;
    }
  }
}
</style>
