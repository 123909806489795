<template>
  <a class="btn btn-secondary btn-sm btn-download rounded-sm px-5">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonDownload',
}
</script>