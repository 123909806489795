<template>
  <Card class="credit-card-list">
    <CardLoader v-if="getLoadingStatus()">
      <rect x="0"
            y="4"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="24"
            width="150"
            height="12"
            rx="3" />
      <rect x="0"
            y="56"
            width="400"
            height="12"
            rx="3" />
      <rect x="0"
            y="76"
            width="370"
            height="12"
            rx="3" />
      <rect x="0"
            y="96"
            width="178"
            height="12"
            rx="3" />
    </CardLoader>

    <template #header>
      <div class="row w-100 p-0 m-0">
        <div class="title col-md-6">
          <h3 class="text-dark font-size-secondary m-0">
            {{ $ml.get('creditCards') }}
          </h3>
        </div>
        <div class="col-md-6 text-right card-buttons"
             v-if="!getLoadingStatus()">
          <b-button variant="danger"
                    size="sm"
                    class="rounded-sm"
                    v-if="creditCards.length > 0"
                    @click="deleteSelectedCards()">{{ $ml.get('deleteSelected') }}
          </b-button>

          <b-button variant="primary"
                    size="sm"
                    class="rounded-sm ml-2"
                    @click="openNewCardModal()">{{ $ml.get('addNewCard') }}</b-button>
        </div>
      </div>
      <CreditCardModal @addedCreditCard="addedCreditCard"
                       :selectedCard="selectedCard"></CreditCardModal>
    </template>
    <template v-if="!getLoadingStatus()">
      <v-data-table :headers="computedHeaders"
                    :items="creditCards"
                    :options.sync="options"
                    :items-per-page="100"
                    :server-items-length="100"
                    :loading="loading"
                    :hide-default-footer="true"
                    id="card-list">
        <!-- :mobile-breakpoint="0"  -->

        <template v-slot:header.choose>
          <label class="checkbox">
            <input type="checkbox"
                   v-model="checked"
                   @change="checkAll" />
            <span></span>
          </label>
        </template>

        <template v-slot:header.type>
          {{$ml.get('cardType')}}
        </template>
        <template v-slot:header.holder_name>
          {{$ml.get('cardName')}}
        </template>
        <template v-slot:header.number>
          {{$ml.get('cardInformation')}}
        </template>
        <template v-slot:header.is_valid>
          {{$ml.get('isValid')}}
        </template>
        <template v-slot:header.action>
          {{$ml.get('action')}}
        </template>

        <template v-slot:item.choose="{ item }">
          <label class="checkbox">
            <input type="checkbox"
                   v-model="itemIds"
                   :value="item.id" />
            <span></span>
          </label>
        </template>

        <template v-slot:item.star="{ item }">
          <i :class="
                    (!item.is_default && !item.is_valid
                      ? 'cursor-not-change'
                      : 'cursor-change') + (item.is_default ? ' fas' : ' far')
                  "
             class="fa-star text-primary"
             @click="setAsDefault(item)"></i>
        </template>

        <template v-slot:item.type="{ item }">
          <img v-if="item.type != 'default'"
               :src="'/image/' + item.type + '.png'"
               class="img-fluid"
               :alt="item.type" />
          <span v-else
                class="d-flex align-items-center">
            <i class="far fa-credit-card mr-1"></i> Default</span>
        </template>

        <template v-slot:item.number="{ item }">
          {{ `${item.no.split('-')[2]}-${item.no.split('-')[3]}` }}
        </template>

        <template v-slot:item.is_valid="{ item }">
          <span class="verify-credit-card">
            {{ item.is_valid ? $ml.get('valid') : $ml.get('invalid') }}
            <i class="fas fa-check-circle"
               v-b-tooltip.hover
               @click="openVerifyModal(item.id)"
               v-if="!item.is_valid"
               :title="$ml.get('verifyCreditCard')"></i>
          </span>
        </template>

        <template v-slot:item.action="{ item }"
                  class="p-0 actionsButton">
          <ButtonEdit class="mr-2"
                      :class="!item.is_valid ? 'cursor-not-change' : ''"
                      @click.native="openCardModal(item)"></ButtonEdit>
          <ButtonDelete @click.native="deleteCard(item)"></ButtonDelete>
        </template>

        <template v-slot:item.mobileView="{item}">
          <div class="d-flex align-item-center">
            <div class="d-flex">
              <label class="checkbox">
                <input type="checkbox"
                       v-model="itemIds"
                       :value="item.id" />
                <span></span>
              </label>
            </div>

            <div class="d-flex mobile-star pl-5">
              <i :class="
                    (!item.is_default && !item.is_valid
                      ? 'cursor-not-change'
                      : 'cursor-change') + (item.is_default ? ' fas' : ' far')
                  "
                 class="fa-star text-primary"
                 @click="setAsDefault(item)"></i>
            </div>

            <div class="d-flex flex-column pl-5">
              <div class="holder-name">
                {{item.holder_name}}
              </div>
              <div class="d-flex align-item-center">
                <div>
                  <img v-if="item.type != 'default'"
                       :src="'/image/' + item.type + '.png'"
                       class="img-fluid"
                       :alt="item.type" />
                </div>
                <div class="card-no">
                  {{ `${item.no.split('-')[2]}-${item.no.split('-')[3]}` }}
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex ml-auto operation-buttons">
            <ButtonEdit class="mr-2"
                        :class="!item.is_valid ? 'cursor-not-change' : ''"
                        @click.native="openCardModal(item)"
                        v-if="item.is_valid"></ButtonEdit>
            <ButtonDelete @click.native="deleteCard(item)"></ButtonDelete>
          </div>
        </template>

      </v-data-table>

    </template>

    <CreditCardVerifyModal :cardId="selectedCardId"
                           @verifyCreditCard="verifyCreditCard"></CreditCardVerifyModal>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '@/components/Card'
import ButtonEdit from '@/components/buttons/ButtonEdit'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import CreditCardModal from '@/components/CreditCardModal'
import CreditCardVerifyModal from '@/components/CreditCardVerifyModal'
import CardLoader from '@/view/content/CardLoader'
import ApiService from '@/core/services/api.service'

export default {
  name: 'CreditCardList',
  props: {
    isCreditCardLoading: Boolean,
    addNewCardOperation: Boolean,
  },
  data() {
    return {
      checked: false,
      itemIds: [],
      itemIdsProcessCount: 0,
      selectedCardId: '',
      loading: false,
      selectedCard: {},
      options: {},
      headers: [
        { text: '', value: 'choose', sortable: false, isMobile: false },
        { text: '', value: 'star', sortable: false, isMobile: false },
        {
          text: this.$ml.get('cardType'),
          value: 'type',
          sortable: false,
          isMobile: false,
        },
        {
          text: this.$ml.get('cardName'),
          value: 'holder_name',
          sortable: false,
          isMobile: false,
        },
        {
          text: this.$ml.get('cardInformation'),
          value: 'number',
          sortable: false,
          isMobile: false,
        },
        {
          text: this.$ml.get('isValid'),
          value: 'is_valid',
          sortable: false,
          isMobile: false,
        },
        {
          text: this.$ml.get('actions'),
          value: 'action',
          sortable: false,
          align: 'right',
          isMobile: false,
        },
        {
          text: '',
          value: 'mobileView',
          sortable: false,
          isMobile: true,
        },
      ],
      isMobileBrowser: window.innerWidth < 600,
    }
  },
  computed: {
    ...mapGetters(['creditCards']),
    computedHeaders() {
      return this.headers.filter((f) => {
        return f.isMobile === this.isMobileBrowser
      })
    },
  },
  created() {
    if (this.addNewCardOperation) {
      setTimeout(() => {
        this.selectedCard = {}
        this.$bvModal.show('modal-addcard')
      }, 200)
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.isMobileBrowser = window.innerWidth < 600
    })
  },
  methods: {
    checkAll: function () {
      this.itemIds = []
      if (this.checked) {
        for (const i in this.creditCards) {
          this.itemIds.push(this.creditCards[i].id)
        }
      }
    },
    addedCreditCard() {
      this.$emit('addedCreditCard')
    },
    verifyCreditCard() {
      this.$emit('verifyCreditCard')
    },
    openVerifyModal(cardId) {
      this.selectedCardId = cardId
      this.$bvModal.show('modal-credit-card-verify-modal')
    },
    openCardModal(card) {
      if (card && card.is_valid) {
        this.selectedCard = card
        this.$bvModal.show('modal-addcard')
      }
    },
    openNewCardModal() {
      this.selectedCard = {}
      this.$bvModal.show('modal-addcard')
    },
    setAsDefault(card) {
      if (!card.is_default && card.is_valid) {
        this.loading = true
        ApiService.put(`credit-cards/${card.id}/mark-as-default`).then(
          (res) => {
            // console.log(res)
            this.$emit('addedCreditCard')
            this.loading = false
          }
        )
      }
    },
    deleteCard(card) {
      this.$bvModal
        .msgBoxConfirm(this.$ml.get('doYouWantToDeleteCreditCard'), {
          title: this.$ml.get('pleaseConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$ml.get('yes'),
          cancelTitle: this.$ml.get('no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true
            ApiService.delete(`credit-cards/${card.id}`)
              .then((res) => {
                // console.log(res)
                this.$emit('addedCreditCard')
                this.loading = false
                this.generateToast(
                  'success',
                  this.$ml.get('deletedCreditCardSuccess')
                )
              })
              .catch((err) => {
                this.loading = false

                if (err == 'Error') {
                  this.generateToast(
                    'danger',
                    this.$ml.get('anErrorHasOccurred')
                  )
                }
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deleteSelectedCards() {
      if (this.itemIds.length === 0) {
        this.generateToast('danger', this.$ml.get('noSelection'))
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$ml.get('doYouWantToDeleteSelectedCreditCard'), {
            title: this.$ml.get('pleaseConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: this.$ml.get('yes'),
            cancelTitle: this.$ml.get('no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true
              for (const i in this.itemIds) {
                ApiService.delete(`credit-cards/${this.itemIds[i]}`)
                  .then((res) => {
                    // console.log(res)
                    // this.generateToast(
                    //   'success',
                    //   this.$ml.get('deletedCreditCardSuccess')
                    // )
                  })
                  .catch((err) => {
                    // if (err == 'Error') {
                    //   this.generateToast(
                    //     'danger',
                    //     this.$ml.get('anErrorHasOccurred')
                    //   )
                    // }
                  })
                  .finally(() => {
                    this.itemIdsProcessCount++
                  })
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    getLoadingStatus() {
      return this.isCreditCardLoading || this.loading
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  components: {
    Card,
    ButtonEdit,
    ButtonDelete,
    CreditCardModal,
    CreditCardVerifyModal,
    CardLoader,
  },
  watch: {
    itemIdsProcessCount: function (value) {
      if (this.itemIds.length == value) {
        this.itemIdsProcessCount = 0
        this.$emit('addedCreditCard')
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.credit-card-list {
  #card-list tr:hover > td > .checkbox > span {
    border-color: #ffffff;
  }

  #card-list {
    .cursor-not-change {
      cursor: not-allowed;
    }
    .cursor-change {
      cursor: pointer;
    }
    .column-action {
      min-width: 115px;
    }
    td:last-child {
      width: 85px;
      padding: 0px;
    }
    .mobile-star {
      align-items: center;
      position: relative;
      text-align: left;
    }
    .holder-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding-left: 7px;
    }
    .card-no {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #8a94a6;
      padding-top: 9px;
    }

    .operation-buttons {
      padding-top: 20px;
      a {
        width: 50%;
      }
    }
  }

  .verify-credit-card {
    display: flex;
    align-items: center;

    i {
      color: #f64e60;
      margin-left: 4px;
      cursor: pointer;
    }
  }

  #card-list table {
    min-height: 120px;
  }
}

@media (max-width: 767px) {
  .credit-card-list {
    .card-buttons {
      text-align: left !important;
      margin-top: 20px !important;
      button {
        width: 48%;
      }
    }
    td:last-child {
      width: 100% !important;
      padding: 16px !important;
    }
    .v-data-table__mobile-row__cell {
      width: calc(100vw - 102px) !important;
    }
  }
  #modal-teknosa-stash-banner-payment {
    .v-data-table__mobile-row__cell {
      width: 100% !important;
    }
  }
}
</style>
