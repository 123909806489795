<template>
  <Card class="card-bg-primary">
    <div class="current-subs d-flex flex-column flex-md-row">
      <div class="d-flex flex-md-fill">
        <div class="text mr-3">
          <h3 class="text-white font-size-secondary m-0">Current Subscription</h3>
          <p class="text-white font-weight-light mt-3">
            Your standard monthly subscription will automatically renew on
            <span class="font-weight-bolder">Jan 10, 2019</span> and billing
            information is updated monthly.
          </p>
        </div>
      </div>
      <div class="mt-3 text-nowrap display-4">205,90 TL/m</div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card'

export default {
  name: 'CurrentSubs',
  components: {
    Card,
  },
}
</script>

<style>
.column .current-subs {
    flex-direction: column !important;
}
</style>
