<template>
  <b-modal id="pay-invoice-modal"
           centered
           hide-header
           hide-footer
           :size="selectedInvoice.total || selectedInvoiceAddress ? 'md': 'xl'"
           class="flex-cloumn">
    <div class="title">
      <h3 class="text-dark font-size-secondary mr-3 mb-5">
        {{ $ml.get('payInvoice') }}
      </h3>
    </div>

    <div class="form"
         :disabled="loading">

      <p class="mb-3"
         v-if="selectedInvoice.total">
        {{ $ml.get('selectedInvoiceAmount') }}:
        <b v-if="selectedInvoice.total || selectedInvoiceAddress">
          {{ `${selectedInvoice.total} ${selectedInvoice.currency_code}` }}
        </b>
        <b v-else>
          {{ `${selectedInvoice.amount} ${selectedInvoice.currency_code} + ${$ml.get('vat')}` }}
        </b>
      </p>

      <p class="mb-3"
         v-if="!selectedInvoice.total && !selectedInvoiceAddress">{{ $ml.get('notRegisteredAddress') }}</p>

      <multiselect id="creditCardDropdown"
                   v-if="selectedInvoice.total || selectedInvoiceAddress"
                   v-model="selectedCreditCard"
                   label="no"
                   track-by="no"
                   :options="validCreditCards"
                   :allowEmpty="false"
                   :show-labels="false"
                   :searchable="true"
                   :placeholder="$ml.get('pleaseSelectYourCreditCard')"
                   required></multiselect>

      <AddressTable v-else
                    @setInvoiceAddress="setInvoiceAddress"
                    @addressListData="addressListData"></AddressTable>

      <div class="no-address"
           v-if="addressList.length <= 0 && !selectedInvoice.total">
        <p>
          {{ $ml.get('notRegisteredAddressAddPlease') }}
          <router-link to="/general">{{ $ml.get('addNewAddress') }}</router-link>
        </p>
      </div>

      <b-button variant="primary"
                class="mt-3"
                block
                :disabled="loading || (!selectedInvoice.total && !selectedInvoiceAddress)"
                @click="payInvoice">{{ $ml.get('pay') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'

import AddressTable from '@/components/invoice/AddressTable'

export default {
  name: 'PayInvoiceModal',
  props: {
    selectedInvoice: Object,
  },
  data() {
    return {
      loading: false,
      selectedCreditCard: { no: this.$ml.get('pleaseSelectYourCreditCard') },
      selectedInvoiceAddress: false,
      addressList: [],
    }
  },
  mounted() {
    // Changed lang events
    this.$ml.bus.$on('vueml-language-changed', () => {
      this.selectedCreditCard.no = this.$ml.get('pleaseSelectYourCreditCard')
    })
  },
  computed: {
    ...mapGetters(['creditCards']),
    validCreditCards() {
      return this.creditCards.filter((item) => item.is_valid)
    },
  },
  methods: {
    payInvoice() {
      if (
        this.selectedCreditCard.no != this.$ml.get('pleaseSelectYourCreditCard')
      ) {
        this.loading = true
        ApiService.post(`invoicing/${this.selectedInvoice.id}/pay`, {
          credit_card_id: this.selectedCreditCard.id,
        })
          .then((res) => {
            this.loading = false
            this.generateToast(
              'success',
              this.$ml.get('yourBillingPaymentIsSuccessful')
            )
            this.$bvModal.hide('pay-invoice-modal')
            this.$emit('invoiceBeenPaid')
          })
          .catch((err) => {
            this.loading = false
            this.selectedCreditCard = {
              no: this.$ml.get('pleaseSelectYourCreditCard'),
            }

            if (err == 'Error') {
              this.generateToast('danger', this.$ml.get('anErrorHasOccurred'))
            }
          })
      } else {
        this.generateToast('danger', this.$ml.get('pleaseSelectYourCreditCard'))
      }
    },
    setInvoiceAddress(payload) {
      this.selectedInvoiceAddress = payload
    },
    addressListData(payload) {
      this.addressList = payload
    },
    generateToast(variant, text) {
      this.$bvToast.toast(text, {
        title: `${
          variant == 'success' ? this.$ml.get('success') : this.$ml.get('error')
        }`,
        variant: variant,
        solid: true,
      })
    },
  },
  components: {
    AddressTable,
  },
}
</script>

<style lang="scss">
#pay-invoice-modal {
  .form-control {
    border-radius: 16px;
    border-color: transparent;
  }

  .modal-dialog .modal-content {
    overflow: visible !important;
  }
}
</style>
