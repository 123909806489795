<template>
  <div class="resource-chart px-5">
    <div class="mb-5">
      <b-dropdown variant="dark"
                  size="sm"
                  class="ml-auto rounded-sm"
                  :text="$ml.get('monthName'+[selectedMonth])">
        <b-dropdown-item v-for="index in 10"
                         :key="index"
                         @click="selectedMonth = index">
          {{$ml.get('monthName'+index) }}
        </b-dropdown-item>
      </b-dropdown>

      <b-dropdown variant="dark"
                  size="sm"
                  class="ml-2"
                  :text="`${selectedWeek}. ${$ml.get('week')}`">
        <b-dropdown-item v-for="index in 5"
                         :key="index"
                         @click="selectedWeek = index">
          {{ `${index}. ${$ml.get('week')}` }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div>
        <h3 class="text-dark font-size-secondary mb-2">{{$ml.get('resourceAmount')}}</h3>
      </div>
    </div>

    <apexchart :options="chartOptions"
               :series="series"
               type="area"></apexchart>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ResourceChart',
  data() {
    return {
      selectedMonth: new Date().getMonth() + 1,
      selectedWeek: 1,
      chartOptions: {},
      series: [
        {
          name: this.$ml.get('destination'),
          data: [0, 2, 4, 2, 5, 7, 3, 2, 4, 2, 5, 7, 3],
        },
        {
          name: this.$ml.get('present'),
          data: [0, 1, 3, 1, 3, 5, 0, 1, 3, 1, 3, 5, 0],
        },
      ],
    }
  },
  created() {
    this.setChart()
  },
  computed: {
    ...mapGetters(['layoutConfig']),
  },
  mounted() {
    this.chartOptions = {
      chart: {
        type: 'area',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
        height: 280,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [
          // this.layoutConfig('colors.theme.base.primary'),
          // this.layoutConfig('colors.theme.base.danger'),
          '#d0e6ff',
          '#FFCFCF',
        ],
      },
      xaxis: {
        show: false,
      },
      yaxis: {
        show: false,
        min: 0,
        // max: function (max) {
        //   return max + 1
        // },
      },
      tooltip: {
        style: {
          fontSize: '12px',
          fontFamily: this.layoutConfig('font-family'),
        },
      },
      colors: [
        // this.layoutConfig('colors.theme.light.primary'),
        // this.layoutConfig('colors.theme.light.danger'),
        '#d0e6ff',
        '#FFCFCF',
      ],
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
    }
  },
  methods: {
    setChart() {
      // console.log('memberchart.filters :>> ', this.filters)
      // console.log('memberchart.selectedMonth :>> ', this.selectedMonth)
    },
  },
  watch: {
    filters() {
      this.setChart()
    },
    selectedMonth() {
      this.setChart()
    },
  },
}
</script>
 
<style lang="scss">
.resource-chart {
  .dropdown-toggle {
    border-radius: 0.28rem;
  }
}
</style>
