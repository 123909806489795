<template>
  <a class="btn btn-primary btn-sm btn-print rounded-sm px-5">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ButtonPrint',
}
</script>