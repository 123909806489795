<template>
  <b-modal id="modal-invoice"
           centered
           hide-footer
           header-bg-variant="primary"
           header-text-variant="light"
           hide-header-close
           size="xl">
    <template v-slot:modal-header>
      <div class="header-content w-100 d-flex flex-column px-20 py-10">
        <div class="d-flex mb-8">
          <div class="title">INVOICE</div>
          <div class="text-right ml-auto">
            <div class="subtitle mb-3">{{this.appName()}}</div>
            <p>
              Cecilia Chapman, 711-2880 Nulla St, Mankato
              <br />Mississippi 96522
            </p>
          </div>
        </div>
        <div class="pt-6 border-top">
          <div class="row">
            <div class="col-4">
              DATE
              <br />
              <span class="light">{{ date }}</span>
            </div>
            <div class="col-4">
              INVOICE NO.
              <br />
              <span class="light">{{ invoiceNo }}</span>
            </div>
            <div class="col-4">
              INVOICE TO.
              <br />
              <span class="light">{{ invoiceTo }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <b-container fluid
                 class="bg-white px-27 pb-6">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-muted text-left pl-0">DESCRIPTION</th>
              <th class="text-muted text-right">HOURS</th>
              <th class="text-muted text-right">RATE</th>
              <th class="text-muted text-right pr-0">AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items"
                :key="item.id">
              <td class="font-weight-bold pl-0">{{ item.description }}</td>
              <td class="font-weight-bold text-right">{{ item.hours }}</td>
              <td class="font-weight-bold text-right">{{ item.rate }}</td>
              <td class="font-weight-bold text-right pr-0 text-danger">{{ item.hours * item.rate | formatPrice }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </b-container>
    <b-container fluid
                 class="bg-light px-27 py-6">
      <div class="row">
        <div class="col-4">
          <div class="subtitle mb-3">BANK TRANSFER</div>
          <p>
            Account Name:
            <span class="float-right">Barclays UK</span>
          </p>
          <p>
            Account Number:
            <span class="float-right">1234567890934</span>
          </p>
          <p>
            Code:
            <span class="float-right">BARC0032UK</span>
          </p>
        </div>
        <div class="col-8 text-right">
          <div class="subtitle mb-3">TOTAL AMOUNT</div>
          <p class="text-big text-danger">{{ totalAmount | formatPrice }}</p>
          <p>Taxes Included</p>
        </div>
      </div>
    </b-container>
    <b-container fluid
                 class="bg-white d-flex px-27 py-6">
      <ButtonDownload>Download</ButtonDownload>
      <ButtonPrint class="ml-auto">Print</ButtonPrint>
    </b-container>
  </b-modal>
</template>

<script>
import ButtonDownload from '@/components/buttons/ButtonDownload'
import ButtonPrint from '@/components/buttons/ButtonPrint'

export default {
  name: 'InvoiceModal',
  data() {
    return {
      date: 'Dec 12, 2017',
      invoiceNo: 'GS 000014',
      invoiceTo:
        'Iris Watson, P.O. Box 283 8562 Fusce RD. Fredrick Nebraska 20620',
      items: [
        {
          id: 1,
          description: 'Creative Design',
          hours: 80,
          rate: 40,
        },
        {
          id: 1,
          description: 'Front-End Development',
          hours: 120,
          rate: 40,
        },
        {
          id: 1,
          description: 'Back-End Development',
          hours: 210,
          rate: 60,
        },
      ],
    }
  },
  filters: {
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
      return formatter.format(value)
    },
  },
  computed: {
    totalAmount: function () {
      var sum = 0
      this.items.forEach((e) => {
        sum += e.hours * e.rate
      })
      return sum
    },
  },
  methods: {
    appName() {
      return process.env.VUE_APP_NAME
    },
  },
  components: {
    ButtonDownload,
    ButtonPrint,
  },
}
</script>

<style lang="scss">
#modal-invoice {
  .modal-header {
    border-radius: 0;
    .title {
      font-size: 2rem;
      font-weight: bold;
    }
    .subtitle {
      font-size: 1rem;
      font-weight: 500;
    }
    .light {
      opacity: 0.7;
    }
  }
  .modal-content {
    border-radius: 0;
  }
  .modal-body {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: #fff;
    font-size: 0.9rem;
    line-height: 2;
    .subtitle {
      font-weight: 500;
    }
    .text-big {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}
</style>
